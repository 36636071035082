import { navigate } from 'gatsby';
import { isBrowser } from '../../utils';
import { currentSession } from '../../redux/actions/authActions';
import { useDispatch } from 'react-redux';
import { ROUTE_APP_DASHBOARD, ROUTE_AUTH_LOGIN } from '../../constants';

const LandingPage = () => {
  const dispatch = useDispatch();

  if (isBrowser()) {
    dispatch(currentSession()).then(result => {
      if (result) {
        navigate(ROUTE_APP_DASHBOARD);
      } else if (isBrowser()) {
        navigate(ROUTE_AUTH_LOGIN);
      }
    });
  }

  return null;
};

export default LandingPage;
