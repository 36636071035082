import React from "react"
import Layout from "../layouts/Auth"
import store from "../redux/store/index"
import { Provider } from "react-redux"
import Amplify from "aws-amplify"
import awsconfig from "../aws-exports"
import { CssBaseline } from "@material-ui/core"
import LandingPage from "../components/pages/LandingPage"

Amplify.configure(awsconfig)

const Page = () => (
  <Provider store={store}>
    <CssBaseline />
    <Layout>
      <LandingPage />
    </Layout>
  </Provider>
)

export default Page
